<template lang="pug">
.layout-blank
  transition(name="fade")
    span(
      v-if="!navigate"
      @click="$router.go(-1)"
    )
      i.uil.uil-angle-left
  app-loading(v-show="loading")
  div(v-show="!loading")
    slot
</template>

<script>
import AppLoading from "@/components/AppLoading";
import { computed } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { store } from "@/store";

export default {
  components: { AppLoading },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || "Default";
    });
    const navigate = computed(() => {
      return route.meta.unnavigate || false;
    });
    const loading = computed(() => {
      return store.APP_LOADING;
    });

    return { layout, navigate, loading };
  },
};
</script>

<style lang="scss" scoped>
span {
  @apply inline-block;
  @apply h-12;
  @apply w-12;
  @apply rounded-full;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply transition-all;
  @apply cursor-pointer;
  i {
    @apply text-4xl;
    @apply text-gignetgreen-500;
  }
  &:hover {
    @apply bg-white;
    i {
      @apply text-gignetgreen-400;
    }
  }
  &:focus {
    @apply bg-none;
  }
}

.layout-blank {
  @apply p-5;
  @apply h-full;
}

.fade-enter-active {
  transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
